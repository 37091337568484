exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-calculators-affordability-tsx": () => import("./../../../src/pages/calculators/affordability.tsx" /* webpackChunkName: "component---src-pages-calculators-affordability-tsx" */),
  "component---src-pages-calculators-down-payment-tsx": () => import("./../../../src/pages/calculators/down-payment.tsx" /* webpackChunkName: "component---src-pages-calculators-down-payment-tsx" */),
  "component---src-pages-calculators-energy-efficiency-tsx": () => import("./../../../src/pages/calculators/energy-efficiency.tsx" /* webpackChunkName: "component---src-pages-calculators-energy-efficiency-tsx" */),
  "component---src-pages-calculators-help-to-buy-tsx": () => import("./../../../src/pages/calculators/help-to-buy.tsx" /* webpackChunkName: "component---src-pages-calculators-help-to-buy-tsx" */),
  "component---src-pages-calculators-index-tsx": () => import("./../../../src/pages/calculators/index.tsx" /* webpackChunkName: "component---src-pages-calculators-index-tsx" */),
  "component---src-pages-calculators-mortgage-overpayment-tsx": () => import("./../../../src/pages/calculators/mortgage-overpayment.tsx" /* webpackChunkName: "component---src-pages-calculators-mortgage-overpayment-tsx" */),
  "component---src-pages-calculators-mortgage-stress-test-tsx": () => import("./../../../src/pages/calculators/mortgage-stress-test.tsx" /* webpackChunkName: "component---src-pages-calculators-mortgage-stress-test-tsx" */),
  "component---src-pages-calculators-refinance-tsx": () => import("./../../../src/pages/calculators/refinance.tsx" /* webpackChunkName: "component---src-pages-calculators-refinance-tsx" */),
  "component---src-pages-calculators-stamp-duty-tsx": () => import("./../../../src/pages/calculators/stamp-duty.tsx" /* webpackChunkName: "component---src-pages-calculators-stamp-duty-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-how-to-index-tsx": () => import("./../../../src/pages/how-to/index.tsx" /* webpackChunkName: "component---src-pages-how-to-index-tsx" */),
  "component---src-pages-how-to-save-rightmove-listing-for-later-tsx": () => import("./../../../src/pages/how-to/save-rightmove-listing-for-later.tsx" /* webpackChunkName: "component---src-pages-how-to-save-rightmove-listing-for-later-tsx" */),
  "component---src-pages-how-to-save-zoopla-listing-for-later-tsx": () => import("./../../../src/pages/how-to/save-zoopla-listing-for-later.tsx" /* webpackChunkName: "component---src-pages-how-to-save-zoopla-listing-for-later-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-store-redirect-tsx": () => import("./../../../src/pages/store-redirect.tsx" /* webpackChunkName: "component---src-pages-store-redirect-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

